import {
  Component,
  effect,
  ElementRef,
  model,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CrmControlDirective, crmKillEvent } from 'common-module/core';
import { CrmTranslatePipe } from 'common-module/translate';
import { DateTime, DayNumbers, MonthNumbers } from 'luxon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select/select.types';
import { padStart } from 'lodash-es';

@Component({
  standalone: true,
  selector: 'app-date2',
  template: `
    <div class="date2-part date2-month">
      <nz-select
        style="width: 100%"
        nzSize="large"
        [nzOptions]="months"
        [(ngModel)]="month"
        [nzPlaceHolder]="'generic.month' | crmTranslate"
        [nzDropdownMatchSelectWidth]="false"
        [nzShowSearch]="true"
        [nzAllowClear]="true"
        [nzStatus]="status()"
        [nzDisabled]="disabled()"
      ></nz-select>
    </div>
    <div class="date2-part date2-day">
      <input
        #dayElement
        nz-input
        nzSize="large"
        [ngModel]="day()"
        [nzStatus]="status()"
        [disabled]="disabled()"
        (input)="handleDayInput($event)"
        placeholder="DD"
      />
    </div>
    <div class="date2-part date2-year">
      <input
        #yearElement
        nz-input
        nzSize="large"
        [ngModel]="year()"
        [nzStatus]="status()"
        [disabled]="disabled()"
        (input)="handleYearInput($event)"
        (blur)="handleYearBlur($event)"
        placeholder="YYYY"
      />
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
      }

      .date2-part + .date2-part {
        margin-left: 8px;
      }

      .date2-month {
        flex: 1;
        min-width: 0;
      }

      .date2-day {
        width: 48px;
      }

      .date2-year {
        width: 64px;
      }
    `,
  ],
  imports: [NzSelectModule, FormsModule, NzInputModule, CrmTranslatePipe],
})
export class Date2Component extends CrmControlDirective<DateTime> {
  protected dayElement = viewChild<ElementRef<HTMLInputElement>>('dayElement');
  protected yearElement =
    viewChild<ElementRef<HTMLInputElement>>('yearElement');

  protected month = model<MonthNumbers | undefined>(undefined);
  protected day = signal<DayNumbers | undefined>(undefined);
  protected year = signal<number | undefined>(undefined);

  protected months: NzSelectOptionInterface[] = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ];

  constructor() {
    super();

    effect(() => {
      const month = this.month();
      const year = this.year();
      const maxDay = this.getMaxDay(month, year);

      const _day = this.day();
      const day = _day && _day > maxDay ? maxDay : _day;

      if (day && _day && _day > day) {
        untracked(() => this.day.set(day));
        this.setDayValue(day);
      }

      if (day != null && month != null && year != null) {
        const newDateTime = DateTime.fromFormat(
          [
            padStart(String(day), 2, '0'),
            padStart(String(month), 2, '0'),
            year,
          ].join('-'),
          'dd-MM-yyyy',
        );

        untracked(() => this.internalUpdateModel(newDateTime));
      }
    });
  }

  protected override onUpdate(date: DateTime | null) {
    super.onUpdate(date);

    const { day, month, year } = date ?? {};

    this.day.set(day);
    this.month.set(month);
    this.year.set(year);
  }

  protected handleDayInput(event: Event) {
    crmKillEvent(event);

    const value = (event.target as HTMLInputElement).value ?? '';
    const lastSymbol = value.at(value.length - 1);

    if (!lastSymbol) {
      this.day.set(undefined);
      return;
    }

    let day = +value;

    if (value.length > 2) {
      const slice = value.slice(0, 2);
      day = +slice;
      this.setDayValue(day);
    } else if (!/\d/.test(lastSymbol)) {
      const slice = value.slice(0, -1);
      day = +slice;
      this.setDayValue(day);
    }

    if (Number.isNaN(day) || day === 0) {
      this.day.set(undefined);
      this.setDayValue();
      return;
    }

    const maxDay = this.getMaxDay(this.month(), this.year());
    day = day > maxDay ? maxDay : day;
    this.day.set(day as DayNumbers);
    this.setDayValue(day);
  }

  protected handleYearInput(event: Event) {
    crmKillEvent(event);

    const value = (event.target as HTMLInputElement).value ?? '';
    const lastSymbol = value.at(value.length - 1);

    if (!lastSymbol) {
      this.year.set(undefined);
      return;
    }

    let year = +value;

    if (value.length > 4) {
      const slice = value.slice(0, 4);
      year = +slice;
      this.setYearValue(year);
    } else if (!/\d/.test(lastSymbol)) {
      const slice = value.slice(0, -1);
      year = +slice;
      this.setYearValue(year);
    }

    if (Number.isNaN(year) || year === 0) {
      this.year.set(undefined);
      this.setYearValue();
      return;
    }

    if (String(year).length === 4) {
      this.year.set(year);
      this.setYearValue(year);
    }
  }

  protected handleYearBlur(event: Event) {
    crmKillEvent(event);

    const year = this.year();

    if (year) {
      this.setYearValue(year);
    }
  }

  protected getMaxDay(month: number | undefined, year: number | undefined) {
    if (!month || !year) {
      return 31;
    }

    return DateTime.now().set({ month, year }).endOf('month').day;
  }

  private setDayValue(value?: number | undefined) {
    const el = this.dayElement()?.nativeElement;

    if (el) {
      el.value = value ? String(value) : '';
    }
  }

  private setYearValue(value?: number | undefined) {
    const el = this.yearElement()?.nativeElement;

    if (el) {
      el.value = value ? String(value) : '';
    }
  }
}
